export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {},
    '.containerScrolled': {
      backgroundColor: 'black'
    },

    '.smallNavMenu': {
      '.navItem': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        a: {
          transition: 'all ease-in-out 0.5s',
          fontFamily: 'subheading',
          fontSize: '0.85rem',
          letterSpacing: '0px',
          borderBottom: 'solid 1px',
          borderColor: 'transparent',
          color: 'white',
          ':hover': {
            borderBottom: 'solid 1px',
            borderColor: 'inherit'
          }
        }
      },
      '.reservations': {
        variant: 'buttons.primary',
        backgroundColor: 'transparent'
      }
    },
    '.logoLocationContainer': {
      position: 'static',
      mr: 'auto',
      img: {
        filter: 'unset'
      }
    },
    '.hamburger': {
      borderColor: 'text',
      div: {
        backgroundColor: 'text'
      }
    },
    // ? === Nav Menu ===

    '.navMenu, .navMenuOpen': {
      '::before': {
        content: "'Pages'",
        padding: '0.5rem',
        mb: '1.5rem',
        fontWeight: 'bold',
        fontSize: '1.25rem',
        opacity: '0.5',
        borderBottom: '1px solid',
        textTransform: 'uppercase'
      },
      color: 'white',
      backgroundColor: 'transparent',
      padding: ['', '', '', '2rem'],
      width: ['100%', '100%', '100%', '100%'],
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      transform: 'unset',
      position: 'fixed',
      left: '0rem',
      right: 'unset',
      '.navItem': {
        textAlign: 'left',
        a: {
          fontFamily: 'subheading',
          fontSize: ['1.5rem', '1.75rem', '2.5rem', '3rem'],
          letterSpacing: '0px',
          fontWeight: 'bold',
          color: 'white',
          transition: 'all ease-in-out 0.7s',
          textDecoration: 'none',
          ':hover': {
            opacity: '0.5'
          }
        }
      },
      '.seperatorLine': {
        display: 'none'
      },
      '.navItemDropdownMenu': {
        position: 'static',
        background: 'transparent',
        textShadow: 'unset'
      }
    },

    '.navMenu': {
      width: ['85%', '60%', '50%', '40%'],
      left: '-100vw',
      top: '0rem',
      right: 'unset',
      transform: 'unset',
      position: 'fixed',
      color: 'white',
      backgroundColor: 'transparent',
      height: '100vh',
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      '.navItem': {
        textAlign: 'left',
        a: {
          fontSize: ['1.5rem', '1.75rem', '2.5rem', '3rem'],
          letterSpacing: '0px',
          fontWeight: 'bold',
          color: 'white',
          transition: 'all ease-in-out 0.7s',
          textDecoration: 'none',
          ':hover': {
            opacity: '0.5'
          }
        }
      }
    },

    '.navBlock': {
      width: ['15%', '40%', '50%', '60%'],
      left: '-100vw',
      top: '0rem',
      right: 'unset',
      height: '100vh',
      position: 'fixed'
    },
    '.navBlockOpen': {
      position: 'fixed',
      backdropFilter: 'blur(1000042px)',
      backgroundImage: 'url(https://img.freepik.com/free-vector/soft-blurred-background_1034-273.jpg?w=2000)',
      backgroundColor: '#000000',
      opacity: 0.98,
      backgroundSize: 'cover',
      filter: 'brightness(0.1)'
    },
    '.phoneSocialContainer': {
      mt: '4rem',
      flexDirection: 'column',
      alignItems: 'flex-start',
      opacity: '0.5',

      '> li': {
        width: 'fit-content',
        fontWeight: 'bold',

        a: {
          fontSize: '20px'
        },
        '.fa-phone': {
          mr: '1rem'
        },
        '.socialLink': {
          svg: {
            width: '26px',
            height: '26px'
          }
        }
      }
    },

    '.navMenuLogo, .smallNavHeading': {
      display: 'none'
    }
  },

  footer: {
    borderTop: 'solid 2px',
    borderColor: 'text',
    '.image': {},
    '.multiButtonContainer': {
      a: {}
    },
    '.contactDetails-container': {},
    '.copyright': {},
    '.socialIconsContainer': {
      a: {}
    },
    '.gonationLogo': {},
    '.poweredByText': {
      justifyContent: 'flex-start'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['1.4rem', '1.5rem', '1.7rem', '1.8rem', '2rem'],
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1rem',
    textTransform: 'uppercase',
    fontFamily: 'heading',
    fontWeight: 'bold'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['2rem', '2.25rem', '2.5rem', '2.75rem', '3rem'],
    marginBottom: '1rem',
    border: 'none',
    fontFamily: 'subheading',
    fontWeight: '400',
    color: 'white',
    letterSpacing: '4px'
  },
  text: {
    lineHeight: '1.75',
    fontSize: '1rem',
    '*': {
      lineHeight: '1.75',
      color: 'inherit',
      fontSize: 'inherit',
      textAlign: 'inherit'
    }
  },

  centerBlock: {
    minHeight: '100vh',
    '.section': {
      zIndex: '200',
      textAlign: 'center'
    },
    '.title': {
      variant: 'customVariants.title',
      order: '1',
      color: 'white',
      fontSize: ['2.5rem', '2.75rem', '3rem', '3.25rem', '3.5rem'],
      mb: '0rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      order: '2',
      fontSize: ['1rem', '1.1rem', '1.3rem', '1.4rem', '1.5rem'],
      textTransform: 'uppercase'
    },
    '.text': {
      variant: 'customVariants.text',
      my: '2rem',
      color: 'white',
      order: '3',
      textAlign: 'center'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide1: {
    background: 'none',
    padding: '5vh 1rem 0rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '115vh',
    padding: '10vh 5vw',
    '.lazyload-wrapper': {
      zIndex: '2'
    },
    '.content': {
      position: ['relative', '', ''],
      padding: '5vw',
      alignItems: 'flex-start'
    },
    '.title': {
      variant: 'customVariants.title',
      marginBottom: '1.5rem',
      order: '1'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      marginBottom: '1.5rem',
      order: '2',
      display: 'flex',
      flexDirection: 'column',
      '::after': {
        mt: '1rem',
        content: "''",
        backgroundColor: 'white',
        width: '100%',
        height: '3px',
        position: 'relative',
        left: '-20%'
      }
    },

    '.text': {
      variant: 'customVariants.text',
      whiteSpace: 'pre-wrap',
      order: '3',
      p: {
        letterSpacing: '1px',
        lineHeight: '1.7'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  contactUsBar: {
    backgroundColor: '#ada89d78',
    '.title': {
      marginBottom: '0rem'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    zIndex: '2',
    '.logoHero': {
      maxWidth: '700px',
      margin: '0rem auto 1.5rem'
    },
    '.hero_content_container': {
      // backgroundColor: '#ffffff85',
      margin: '0rem',
      maxWidth: '600px',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      textAlign: 'center',
      backgroundColor: '#000000c2',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '90%',
      display: 'none !important'
    },
    '.title': {
      variant: 'customVariants.title',
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      order: '1',
      color: 'white',
      textAlign: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
      order: '2',
      color: 'text'
      // display: 'none',
    },
    '.text': {
      variant: 'customVariants.text',

      order: '3',

      p: {
        color: 'white'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      backgroundColor: 'black'
    },
    '::after': {
      content: "''",
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      background: 'linear-gradient(180deg,black,rgb(0 0 0 / 41%) 0%,rgb(0 0 0 / 0%) 50%,black 95%,black)'
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    '.text': {
      variant: 'customVariants.text',
      background: 'unset'
    },
    '.title': {
      color: 'text'
    },
    '.containerPopUp': {
      backgroundColor: 'black',
      maxWidth: '600px',
      '.shoutContentContainerPopUp': {
        color: 'white'
      },
      '.image': {
        objectFit: 'contain',
        objectPosition: 'top'
      }
    }
  },

  homepage2: {
    variant: 'customVariants.sideBySide1'
  },

  homepage3: {
    variant: 'customVariants.centerBlock',
    mt: '10vh',
    '::before': {
      content: "''",
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      background: 'linear-gradient(360deg,#000,rgb(0 0 0 / 31%) 0%,rgb(0 0 0 / 0%) 50%,rgba(0,0,0,.1) 95%,#000)'
    }
  },

  homepage4: {
    backgroundSize: '80%',
    backgroundAttachment: ['', '', 'unset'],
    my: '5vh',
    padding: '0rem',
    minHeight: '80vh',
    backgroundPosition: 'left center',
    alignItems: 'center',
    position: 'relative',
    flexDirection: ['column', '', 'row'],
    overflow: 'hidden',
    '.content': {
      position: ['', '', 'absolute'],
      left: ['0rem', '', '', '7.5%'],
      top: '50%',
      transform: ['', '', 'translateY(-50%)'],
      width: ['100%', '', '45%', '45%'],
      padding: '3rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      textAlign: 'left',
      zIndex: '10',
      '.title': {
        variant: 'customVariants.title',
        mb: 2
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        mb: 2
      },
      '.text': {
        variant: 'customVariants.text',
        mb: 2
      },
      a: {
        order: '5'
      },
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    },
    '.lazyload-wrapper': {
      width: ['', '', '75%'],
      margin: ['', '', '0rem 0rem 0rem auto'],
      right: ['', '', '', '-10%'],
      position: 'relative',
      '::before': {
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: '0rem',
        top: '0rem',
        content: "''",
        background:
          'radial-gradient(circle,rgb(0 0 0 / 0%) 0%, rgba(0,0,0,0%) 40%, rgba(0,0,0,1) 60%,rgba(0,0,0,1) 100%)'
      }
    }
  },
  homepage5: {
    variant: 'customVariants.homepage2'
  },

  homepage6: {
    variant: 'customVariants.centerBlock',
    '.section': {
      alignItems: 'flex-start',
      textAlign: 'left',
      margin: '0rem 0rem 0rem 5%'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    variant: 'customVariants.sideBySide1',
    flexDirection: ['column', 'column', 'column', 'row'],
    'div.content': {
      width: ['100%', '100%', '100%', '50%']
    },
    '.lazyload-wrapper': {
      width: ['100%', '100%', '100%', '50%'],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    img: {
      objectFit: 'contain',
      maxHeight: '700px'
    }
  },

  aboutSlider: {
    padding: '0vh 2rem 10vh',
    '.textContent': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5vh 2rem',
      '.title': {
        variant: 'customVariants.title'
      }
    },
    '.slick-initialized .slick-slide': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      img: {
        maxWidth: ['150px', '', '200px'],
        padding: '1rem'
      }
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================
  menu: {
    margin: '0rem 0rem',
    maxWidth: 'unset',
    padding: ['2rem 1rem', '', '3rem 2rem'],

    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',

    '.backToMenuBtn': {
      variant: 'buttons.secondary',
      margin: '0rem auto 2rem 0rem'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {
      width: ['100%', '', 'calc(50% - 1rem)'],
      borderRadius: '0px',
      position: 'relative'
    },
    '.allInContainerWrapper ': {
      maxWidth: '1200px',
      margin: '0rem auto'
    },
    '.cellImageContainer': {
      overflow: 'hidden'
    },
    '.cellImage': {
      borderRadius: '0px',
      transition: 'all ease-in-out 0.5s',
      ':hover': {
        transform: 'scale(1.1) translate(-50%, -50%)',
        transformOrigin: 'center center'
      }
    },
    '.menuCell': {
      borderRadius: '0px',
      '.imageFill': {
        paddingBottom: '60%'
      }
    },

    '.cellName': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'light',
      margin: '0rem',
      fontSize: ['1.5rem', '', '1.75rem', '2.5rem']
    },

    '.tabsRow': {
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      borderBottom: 'solid 2px black',
      borderTop: 'solid 2px black',
      marginBottom: ['2.5', '', '3.5rem'],
      padding: '1rem 1.5rem 1.5rem',
      '::before': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '3rem'],
        content: "'The Menu'",
        margin: ['0rem 0rem 1.5rem', '', '0rem auto 0rem 0rem'],
        width: ['100%', '', '', 'fit-content'],
        letterSpacing: '0px',
        textTransform: 'capitalize',
        padding: '1rem 0rem',
        fontFamily: 'heading',
        color: '#fff',
        textTransform: 'uppercase'
      },
      '.menuTab': {
        fontSize: '0.9rem',
        border: 'none',
        textTransform: 'capitalize',
        padding: '0.25rem',
        letterSpacing: '-1px',
        fontFamily: 'heading',
        height: 'fit-content',
        fontWeight: '300',
        backgroundColor: 'transparent',
        margin: 'auto 0.25rem',
        ':hover': {
          color: 'primary'
        }
      },
      '.menuTabActive': {
        color: 'text',
        borderBottom: '1px solid',
        borderColor: 'primary'
      }
    },

    '.menuContainer': {
      paddingBottom: '0rem'
    },

    '.menuSectionTitle': {
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      fontSize: ['1.75rem', '2rem', '2.5rem', '3rem'],
      width: '100%',
      borderBottom: 'solid 3px',
      letterSpacing: '2px',
      textTransform: 'uppercase',
      color: '#fff'
    },
    '.menuSectionDescription': {
      textAlign: 'left',
      opacity: '0.75',
      fontSize: '0.9rem',
      color: 'text',
      marginBottom: 'rem'
    },

    '.menuItemContainer, .menuItemContainerImgActive': {
      margin: '0rem 0rem 1rem',
      padding: ['0rem', '', '0rem 1.5rem 0rem 0rem']
    },

    '.menuItemInnerContainer': {
      margin: '0rem'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },

    '.menuItemName': {
      marginBottom: '0rem',
      fontFamily: 'heading',
      color: 'white',
      fontSize: ['1rem', '1rem', '1.1rem', '1.2rem']
    },
    '.menuItemDescription': {
      color: 'text',
      textTransform: 'lowercase'
    }
  },
  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.albumName': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },
    '.eventItemContainer': {
      padding: ['0.5rem', '1rem', '1.5rem', '2rem', '3rem'],
      flexDirection: ['column', 'column', 'row', 'row', 'row'],
      margin: '1.5rem 0rem 1.5rem',
      boxShadow: '2px 2px 10px lightgrey'
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: ['100%', '100%', '50%', '40%', '35%'],
      // height: '100%',
      maxHeight: 'unset',
      objectFit: 'contain',
      objectPosition: 'top',
      cursor: 'pointer',
      transition: 'all ease-in-out 0.8s',
      ':hover': {
        // opacity: '0.8',
        transform: 'scale(1.05)',
        filter: 'brightness(0.6)'
      }
      // border: 'solid 1px lightgrey',
    },
    '.eventItemContent': {
      width: ['100%', '100%', '50%', '60%', '65%'],
      padding: ['1rem', '1.5rem', '2rem', '3rem'],
      marginTop: ['1.5rem', '', '0rem'],
      paddingTop: ['1rem', '', '0rem'],
      borderTop: ['1px solid', '1px solid', 'none', ''],
      borderColor: ['secondary', 'secondary']
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemTitle': {
      lineHeight: ['1.5', '1.5', '1.5', '1.75', '1.75']
    },

    '.eventItemDescription': {
      maxHeight: 'unset',
      overflowY: 'scroll',
      fontSize: ['1rem', '', '', '1.1rem', '1.2rem'],
      lineHeight: ['1.5', '1.5', '1.5', '1.75', '1.75'],
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    },
    '.eventCTABtns': {
      marginTop: '1rem',
      a: {
        variant: 'buttons.primary',
        textDecoration: 'none'
      }
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    '.text': {
      maxWidth: '700px',
      color: 'white',
      mt: '1rem',
      mb: '2.5rem'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'primary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: ['0rem', '', '', '3rem']
    },
    'h3.title': {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      color: 'white',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.1rem', '1.2rem', '1.3rem', '1.5rem', '1.6rem'],
      textAlign: 'center',
      marginBottom: '0rem',
      alignItems: 'center',
      paddingTop: '2rem',
      borderTop: '1px solid lightgrey'
    },
    '.content_container': {
      padding: '1rem'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 2rem'
    },
    '.dayContainer': {
      mb: '0rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem 0rem', '1rem 2rem 0rem', '1rem 4rem 0rem', '2rem 5rem 0rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    },
    iframe: {
      filter: 'invert(0.85)'
    }
  },

  // ? ===============
  // ? === Barcito ===
  // ? ===============
  barcito: {
    variant: 'customVariants.sideBySide1',
    flexDirection: ['column', 'column', 'column', 'row'],
    '.imageContainer': {
      width: ['100%', '100%', '100%', '50%']
    },
    '.content': {
      height: '100%',
      width: ['100%', '100%', '100%', '50%']
    },
    'div.text': {
      a: {
        display: 'inline'
      }
    }
  },

  // ? ===============
  // ? === Parties ===
  // ? ===============
  parties: {
    variant: 'customVariants.sideBySide1',
    minHeight: 'unset',
    flexDirection: ['column', 'column', 'column', 'row'],
    '.imageContainer': {
      width: ['100%', '100%', '100%', '50%']
    },
    '.content': {
      height: '100%',
      width: ['100%', '100%', '100%', '50%'],
      alignItems: 'flex-start',
      textAlign: 'left'
    }
  },

  // ? ====================
  // ? === Reservations ===
  // ? ====================
  reservations: {
    variant: 'customVariants.centerBlock',
    minHeight: 'unset',
    padding: '3rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    'h3.title': {
      mb: '2rem !important',
      textAlign: 'center'
    }
  }
}
