export default {
  heading: 'Domine, sans-serif',
  subheading: 'Raleway',
  body: 'Montserrat, sans-serif',
  body2: 'Open Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Cabin Sketch, cursive',
  googleFonts: [
    'Domine:400,500,600,700',
    'Playfair Display:400,500,600,700',
    'Cabin Sketch',
    'Montserrat:100,200,300,400,500,600,700,800,900',
    'Raleway:100,200,300,400,500,600,700,800,900',
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Comfortaa:100,200,300,400,500,600,700,800,900'
  ]
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
}
