export default {
  text: '#ccb6a2',
  text2: '#f1f1f1',
  primary: '#c75b1b',
  secondary: '#bb0014',
  background: 'black',
  backgroundSecondary: '#202020',
  light: '#FFF',
  dark: '#020303'
}
